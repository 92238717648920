<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";


export default {
	name: "pi-chart",
	components: {Echart},
	data() {
		return {
			options: {

				color:["#87cefa","#ff7f50","#32cd32","#da70d6",],
				legend: {
					y : '10',
					x : 'center',
					textStyle : {
						color : '#ffffff',

					},
					data : ['维度1','维度2','维度3','维度4',],
				},
				calculable : false,
				tooltip : {
					trigger: 'item',
					formatter: "{a}<br/>{b}<br/>{c}条"
				},
				yAxis: [
					{
						type: 'value',

						axisLine:{
							lineStyle:{
								color: '#034c6a'
							},
						},

						axisLabel: {
							textStyle: {
								color: '#fff'
							},
							formatter: function (value) {
								return value + "k"
							},
						},
						splitLine:{
							lineStyle:{
								width:0,
								type:'solid'
							}
						}
					}
				],
				xAxis: [
					{
						type: 'category',
						data : ['周一','周二','周三','周四','周五','周六'],
						axisLine:{
							lineStyle:{
								color: '#034c6a'
							},
						},

						axisLabel: {
							textStyle: {
								color: '#fff'
							},
							formatter: function (value) {
								return value + ""
							},
						},
						splitLine:{
							lineStyle:{
								width:0,
								type:'solid'
							}
						},
					}
				],
				grid:{
					left: '5%',
					right: '5%',
					bottom: '20%',
					containLabel: true
				},
				series : [
					{
						name:'维度1',
						type:'line',
						smooth:true,
						itemStyle: {
							normal: {
								lineStyle: {
									shadowColor : 'rgba(0,0,0,0.4)'
								}
							}
						},
						data:[15, 0, 20, 45, 22.1, 25,].reverse()
					},
					{
						name:'维度2',
						type:'line',
						smooth:true,
						itemStyle: {
							normal: {
								lineStyle: {
									shadowColor : 'rgba(0,0,0,0.4)'
								}
							}
						},
						data:[25, 10, 30, 55, 32.1, 35, ].reverse()
					},
					{
						name:'维度3',
						type:'line',
						smooth:true,
						itemStyle: {
							normal: {
								lineStyle: {
									shadowColor : 'rgba(0,0,0,0.4)'
								}
							}
						},
						data:[35, 20, 40, 65, 42.1, 45, ].reverse()
					},
					{
						name:'维度4',
						type:'line',
						smooth:true,
						itemStyle: {
							normal: {
								lineStyle: {
									shadowColor : 'rgba(0,0,0,0.4)'
								}
							}
						},
						data:[45, 30, 50, 75, 52.1, 55, 6].reverse()
					}
				]

			}
		}
	}
}
</script>

<style scoped>

</style>
