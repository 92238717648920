<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";

export default {
	name: "leftCenter",
	components: {
		Echart
	},
	data() {
		return {
			title:{
				show:true,
                text:'人员统计'
            },
			options: {
				tooltip : {
					trigger: 'axis',
					axisPointer : {            // 坐标轴指示器，坐标轴触发有效
						type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
					}
				},
				grid: {
					x: 46,
					y:30,
					x2:32,
					y2:40,
					borderWidth: 0
				},
				xAxis:  {
					type: 'value',
					x:'180',
					splitLine: {
						show: false
					},
					axisLabel: {
						show: true,
						textStyle: {
							color: '#a4f7fb'
						}
					}
				},
				yAxis: {
					type: 'category',
					data: ['人员a','人员b','人员c','人员d','人员e'],
					splitLine: {
						show: false
					},
					axisLabel: {
						show: true,
						textStyle: {
							color: '#a4f7fb'
						}
					}
				},
				series: [
					{
						name: 'xx率（%）',
						type: 'bar',
						stack: '总量',
						label: {
							normal: {
								show: true,
								position: 'insideRight'
							}
						},
						data: [60, 72, 85, 96, 99],
						itemStyle: {
							normal: {
								color:"#45c0ff"
							}
						}
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
