<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
	name: "pi-chart",
	components:{Echart},
	data() {
		return {
			options: {


				tooltip : {
					trigger: 'axis'
				},
				legend: {
					orient : 'vertical',
					x : 'left',
					data:['投入量','产出量'],
					textStyle:{
						color:"#f9fbee"

					},
				},

				calculable : false,
				xAxis : [
					{
						type : 'category',
						splitLine:{show: false},
						axisLabel : {
							formatter: '{value} ',
							textStyle: {
								color: '#f4f7ab',
								align: 'right'
							}
						},
						data : ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月']
					}
				],
				yAxis : [
					{
						type : 'value',
						splitLine:{show: false},
						axisLabel : {
							formatter: '{value} ',
							textStyle: {
								color: '#f4f7ab',
								align: 'right'
							}
						}
					}
				],
				grid:{
					borderWidth:0
				},
				series : [
					{
						name:'投入量',
						type:'bar',
						data:[2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
						itemStyle: {
							normal: {
								color: '#2481ff'
							}
						},
						markPoint : {
							data : [
								{type : 'max', name: '最大值'},
								{type : 'min', name: '最小值'}
							]
						},
						markLine : {
							data : [
								{type : 'average', name: '平均值'}
							]
						}
					},
					{
						name:'产出量',
						type:'bar',
						data:[2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3],
						itemStyle: {
							normal: {
								color: '#1afffd'
							}
						},

						markPoint : {
							data : [
								{name : '最高', value : 182.2, xAxis: 7, yAxis: 183, symbolSize:18},
								{name : '最低', value : 2.3, xAxis: 11, yAxis: 3}
							]
						},
						markLine : {
							data : [
								{type : 'average', name : '平均值'}
							]
						}
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
