<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";
var myColor = ['#1089E7', '#F57474', '#56D0E3', '#F8B448', '#8B78F6'];
export default {
	name: "pi-chart",
	components: {Echart},
	data() {
		return {
			options: {


				grid: {
					top: '10%',
					left: '20%',
                    bottom:'10%'
				},
				xAxis: {
					show: false
				},
				yAxis: [{
					show: true,
					data: ['维度1', '维度2', '维度3', '维度4', '维度5', '维度6', '维度7', '维度8'],
					inverse: true,
					axisLine: {
						show: false
					},
					splitLine: {
						show: false
					},
					axisTick: {
						show: false
					},
					axisLabel: {
						color: '#fff',
						formatter: (value, index) => {
							return [

								`{lg|${index + 1}}  ` + '{title|' + value + '} '
							].join('\n')
						},
						rich: {
							lg: {
								backgroundColor: '#339911',
								color: '#fff',
								borderRadius: 15,
								// padding: 5,
								align: 'center',
								width: 15,
								height: 15
							},
						}
					},


				}, {
					show: true,
					inverse: true,
					data: [4000, 3000, 2000, 1000,8000, 900,4000,2222],
					axisLabel: {
						textStyle: {
							fontSize: 12,
							color: '#fff',
						},
					},
					axisLine: {
						show: false
					},
					splitLine: {
						show: false
					},
					axisTick: {
						show: false
					},

				}],
				series: [{
					name: '条',
					type: 'bar',
					yAxisIndex: 0,
					data: [60, 80, 70, 90,30,50,40,20],
					barWidth: 20,
					itemStyle: {
						normal: {
							barBorderRadius: 20,
							color: function (params) {
								var num = myColor.length;
								return myColor[params.dataIndex % num]
							},
						}
					},
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{c}%'
						}
					},
				}, {
					name: '框',
					type: 'bar',
					yAxisIndex: 1,
					barGap: '-100%',
					data: [100, 100, 100, 100,100,100,100,100],
					barWidth: 23,
					itemStyle: {
						normal: {
							color: 'none',
							borderColor: '#00c1de',
							borderWidth: 1,
							barBorderRadius: 15,
						}
					}
				},]
			}
		}
	}
}
</script>

<style scoped>

</style>
