<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
	name: "pi-chart",
	components:{Echart},
	data() {
		return {
			options: {
				title: {
					text: ''
				},
				tooltip: {
					trigger: 'axis'
				},
				legend: {
					data:['维度二','维度三','维度四','维度五','维度六','维度七'],
					textStyle:{
						color: '#fff'
					},
					top: '8%'
				},
				grid: {
					top: '40%',
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				color: ['#FF4949','#FFA74D','#FFEA51','#4BF0FF','#44AFF0','#4E82FF','#584BFF','#BE4DFF','#F845F1'],
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: ['2019年','2020年','2021年','2022年','2023年'],
					splitLine: {
						show: false
					},
					axisLine: {
						lineStyle: {
							color: '#fff'
						}
					}
				},
				yAxis: {
					name: '万',
					type: 'value',
					splitLine: {
						show: false
					},
					axisLine: {
						lineStyle: {
							color: '#fff'
						}
					}
				},
				series: [
					{
						name:'维度二',
						type:'line',
						data:[3961.88, 4233.63, 4183.14, 3633.01, 3704.47]
					},
					{
						name:'维度三',
						type:'line',
						data:[3374.76, 3364.76, 3274.76, 3371.82, 3259.87]
					},
					{
						name:'维度四',
						type:'line',
						data:[14.77, 15.17, 13.17, 14.56, 15.84]
					},
					{
						name:'维度五',
						type:'line',
						data:[686.17,847.26,895.22,865.28,886.72]
					},
					{
						name:'维度六',
						type:'line',
						data:[6133.47, 6577.89, 7019.56,6821.48,7294.59]
					},
					{
						name:'维度七',
						type:'line',
						data:[509.60, 862.54, 1481.77,1552.79,1333.62]
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
