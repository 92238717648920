<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";


export default {
	name: "pi-chart",
	components: {Echart},
	data() {
		return {
			options: {
				color: ["#87cefa", "#ff7f50", "#32cd32", "#da70d6",],
				tooltip: {
					trigger: 'item',
					formatter: "{a}<br/>{b}<br/>{c}元"
				},
				legend: {
					data: ['维度1', '维度2', '维度3', '维度4'],
					y: 'bottom',
					x: 'center',
					textStyle: {
						color: '#fff',
						fontSize: 12
					}
				},
				grid: {
					left: '5%',
					right: '5%',
					bottom: '10%',
					containLabel: true
				},
				calculable: true,
				xAxis: [
					{
						type: 'category',
						boundaryGap: false,
						data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
						axisLine: {
							lineStyle: {
								color: '#87cefa'
							},
						},
						axisLabel: {
							interval: 0,
							rotate: 40,

							textStyle: {
								color: '#fff',
								fontSize: 13
							}
						}
					}
				],
				yAxis: [
					{
						type: 'value',
						axisLine: {
							lineStyle: {
								color: '#87cefa'
							},
						},
						splitLine: {
							"show": false
						},
						axisLabel: {
							textStyle: {
								color: '#fff'
							},
							formatter: function (value) {
								return value + "元"
							},
						},
					}
				],
				series: [
					{
						name: '维度1',
						type: 'line',
						smooth: true,
						itemStyle: {normal: {areaStyle: {type: 'default'}}},
						data: [10, 12, 21, 54, 260, 830, 710]
					},
					{
						name: '维度2',
						type: 'line',
						smooth: true,
						itemStyle: {normal: {areaStyle: {type: 'default'}}},
						data: [30, 182, 434, 791, 390, 30, 10]
					},
					{
						name: '维度3',
						type: 'line',
						smooth: true,
						itemStyle: {normal: {areaStyle: {type: 'default'}}},
						data: [1320, 1132, 601, 234, 120, 90, 20]
					},
					{
						name: '维度4',
						type: 'line',
						smooth: true,
						itemStyle: {normal: {areaStyle: {type: 'default'}}},
						data: [320, 132, 61, 34, 20, 9, 2]
					}
				]

			}
		}
	}
}
</script>

<style scoped>

</style>
