<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
	name: "pi-chart",
    components:{Echart},
	data() {
		return {
			options: {
				tooltip: {
					trigger: 'item',
					formatter: "{a} <br/>{b} : {c}万元"
				},
				legend: {
					x: 'center',
					y: '15%',
					data: [ 'aaa', 'bbb', 'ccc','ddd', 'eee', 'fff','ggg','hhh', 'kkk'],
					icon: 'circle',
					textStyle: {
						color: '#fff',
					}
				},
				calculable: true,
				series: [{
					name: '',
					type: 'pie',
					//起始角度，支持范围[0, 360]
					startAngle: 0,
					//饼图的半径，数组的第一项是内半径，第二项是外半径
					radius: [36, 75.75],
					//支持设置成百分比，设置成百分比时第一项是相对于容器宽度，第二项是相对于容器高度
					center: ['50%', '40%'],
					//是否展示成南丁格尔图，通过半径区分数据大小。可选择两种模式：
					// 'radius' 面积展现数据的百分比，半径展现数据的大小。
					//  'area' 所有扇区面积相同，仅通过半径展现数据大小
					roseType: 'area',
					//是否启用防止标签重叠策略，默认开启，圆环图这个例子中需要强制所有标签放在中心位置，可以将该值设为 false。
					avoidLabelOverlap: false,
					label: {
						normal: {
							show: true,
							formatter: '{c}万元'
						},
						emphasis: {
							show: true
						}
					},
					labelLine: {
						normal: {
							show: true,
							length2: 1,
						},
						emphasis: {
							show: true
						}
					},
					data: [{
						value: 900.58,
						name: 'aaa',
						itemStyle: {
							normal: {
								color: '#f845f1'
							}
						}
					},
						{
							value: 1100.58,
							name: 'bbb',
							itemStyle: {
								normal: {
									color: '#ad46f3'
								}
							}
						},
						{
							value: 1200.58,
							name: 'ccc',
							itemStyle: {
								normal: {
									color: '#5045f6'
								}
							}
						},
						{
							value: 1300.58,
							name: 'ddd',
							itemStyle: {
								normal: {
									color: '#4777f5'
								}
							}
						},
						{
							value: 1400.58,
							name: 'eee',
							itemStyle: {
								normal: {
									color: '#44aff0'
								}
							}
						},
						{
							value: 1500.58,
							name: 'fff',
							itemStyle: {
								normal: {
									color: '#45dbf7'
								}
							}
						},
						{
							value: 1500.58,
							name: 'ggg',
							itemStyle: {
								normal: {
									color: '#f6d54a'
								}
							}
						},
						{
							value: 1600.58,
							name: 'hhh',
							itemStyle: {
								normal: {
									color: '#f69846'
								}
							}
						},
						{
							value: 1800,
							name: 'kkk',
							itemStyle: {
								normal: {
									color: '#ff4343'
								}
							}
						},
						{
							value: 0,
							name: "",
							itemStyle: {
								normal: {
									color: 'transparent'
								}
							},
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						},
						{
							value: 0,
							name: "",
							itemStyle: {
								normal: {
									color: 'transparent'
								}
							},
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						},
						{
							value: 0,
							name: "",
							itemStyle: {
								normal: {
									color: 'transparent'
								}
							},
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						},
						{
							value: 0,
							name: "",
							itemStyle: {
								normal: {
									color: 'transparent'
								}
							},
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						},
						{
							value: 0,
							name: "",
							itemStyle: {
								normal: {
									color: 'transparent'
								}
							},
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						},
						{
							value: 0,
							name: "",
							itemStyle: {
								normal: {
									color: 'transparent'
								}
							},
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						},
						{
							value: 0,
							name: "",
							itemStyle: {
								normal: {
									color: 'transparent'
								}
							},
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						},
						{
							value: 0,
							name: "",
							itemStyle: {
								normal: {
									color: 'transparent'
								}
							},
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						},
						{
							value: 0,
							name: "",
							itemStyle: {
								normal: {
									color: 'transparent'
								}
							},
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						}
					]
				}]
			}
		}
	}
}
</script>

<style scoped>

</style>
