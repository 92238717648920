<template>
    <div class="box">
        <div class="left" :style="{paddingTop:box_height}">
            <div style="flex: 1">
                <Box :hide="1">
                    <CenterLeft1></CenterLeft1>
                </Box>

            </div>
            <div style="flex: 1">
                <Box :hide="1">
                    <CenterRight1></CenterRight1>
                </Box>
            </div>

        </div>
        <div class="center">

            <div style="flex: 1">
                <Box title="年xx统计">
                    <centerLeftTop></centerLeftTop>
                </Box>

            </div>
            <div style="flex: 1">
                <Box >
                    <centerLeftcenter></centerLeftcenter>
                </Box>
            </div>
            <div style="flex: 1">
                <Box title="信息分析">
                    <centerbotttom></centerbotttom>
                </Box>
            </div>

        </div>
        <div class="right">
            <div style="flex: 1">
                <Box title="投入统计">
                    <centerrightcenter></centerrightcenter>
                </Box>


            </div>
            <div style="flex: 1">
                <Box title="xx统计">
                    <centerrightBotton></centerrightBotton>
                </Box>
            </div>
            <div style="flex: 2;">


                <Box title="投入产出">
                    <centerRightop></centerRightop>
                </Box>
            </div>
        </div>
        <div>
            <div style="flex: 1">
                <Box title="产值统计">
                    <RightTop></RightTop>
                </Box>


            </div>
            <div style="flex: 2">
                <Box title="人员统计">
                    <testCenter1></testCenter1>
                </Box>
            </div>
            <div style="flex: 1;">

                <Box title="xxx统计">
                    <centerLeft1Chart></centerLeft1Chart>
                </Box>
            </div>
        </div>
    </div>
</template>

<script>
import Box from "_c/box";
import CenterLeft1 from "@/views/digital-factory/components/centerLeft1";
import CenterRight1 from '@/views/digital-factory/components/centerRight1'
import RightTop from "@/views/digital-factory/components/rightTop";
import centerLeftTop from "@/views/digital-factory/components/centerLeftTop";
import centerbotttom from "@/views/digital-factory/components/centerbotttom";
import centerLeftcenter from "@/views/digital-factory/components/centerLeftcenter";
import centerRightop from "@/views/digital-factory/components/centerRightop";
import centerrightcenter from "@/views/digital-factory/components/centerrightcenter";
import centerrightBotton from "@/views/digital-factory/components/centerrightBotton";
import centerLeft1Chart from "@/views/data-report/chart/centerRightChart";
import testCenter1 from "@/views/data-report/chart/testCenter1";
export default {
	name: "dataA",
	components: {
		Box,
		CenterLeft1,centerrightBotton,testCenter1,
        CenterRight1,RightTop,centerLeftTop,centerLeftcenter,centerbotttom,centerRightop,centerrightcenter,centerLeft1Chart
	},
	props: {
		box_height: {
			type: String,
			default() {
				return '110px';
			}
		}
	},
    data(){
		return {

        }
    }
}
</script>

<style scoped lang="less">
.box {
  padding:8px 0;
  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;

    &.center {
      flex: 2;

    }

    &.right {
      flex: 2;
    }
  }

}
</style>
