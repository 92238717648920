<template>
    <div id="centerRight1">
        <div class="bg-color-black">
            <div class="d-flex pt-2 pl-2">
                <div class="d-flex">
                    <span class="fs-xl text mx-2">xx完成排行榜</span>
                </div>
            </div>
            <div class="d-flex jc-center body-box">
                <dv-scroll-board class="dv-scr-board" :config="config"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	data() {
		return {
			config: {
				header: ['xxxx', 'yyyy', 'zzzz率'],
				data: [
					['xxxx1', 'dev-1', "<span  class='colorGrass'>↑75%</span>"],
					['xxxx2', 'dev-2', "<span  class='colorRed'>↓33%</span>"],
					['xxxx3', 'dev-3', "<span  class='colorGrass'>↑100%</span>"],
					['xxxx4', 'rea-1', "<span  class='colorGrass'>↑94%</span>"],
					['xxxx5', 'rea-2', "<span  class='colorGrass'>↑95%</span>"],
					['xxxx6', 'fix-2', "<span  class='colorGrass'>↑63%</span>"],
					['xxxx7', 'fix-4', "<span  class='colorGrass'>↑84%</span>"],
					['xxxx8', 'fix-7', "<span  class='colorRed'>↓46%</span>"],
					['xxxx9', 'dev-2', "<span  class='colorRed'>↓13%</span>"],
					['xxxx10', 'dev-9', "<span  class='colorGrass'>↑76%</span>"]
				],
				rowNum: 9, //表格行数
				headerHeight: 35,
				headerBGC: 'rgba(0,255,255,0.2)', //表头
				oddRowBGC: 'rgba(0,255,255,0.05)', //奇数行
				evenRowBGC: 'rgba(0,255,255,0.1)', //偶数行
				index: true,
				columnWidth: [50],
				align: ['center']
			}
		}
	}
}
</script>

<style lang="less" scoped>
@box-height: 100%;
@box-width: 100%;
#centerRight1 {
  padding: 10px 16px 16px;
  height: @box-height;
  width: @box-width;
  border-radius: 5px;

  .bg-color-black {
    height:calc(100% - 20px);
    border-radius: 10px;
  }

  .text {
    color: #00cbde;
  }

  .body-box {
    border-radius: 10px;
    overflow: hidden;
    height: 100%;

    .dv-scr-board {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
