<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";


export default {
	name: "pi-chart",
	components: {Echart},
	data() {
		return {
			options: {
				color:["#87cefa","#ff7f50","#32cd32","#da70d6",],
				legend: {
					y : '10',
					x : 'center',
					data:['维度1', '维度2','维度3','维度4'],
					textStyle : {
						color : '#ffffff',

					}
				},

				calculable :false,


				grid:{
					left: '5%',
					right: '5%',
					bottom: '10%',
					containLabel: true
				},

				tooltip : {
					trigger: 'axis',
					axisPointer : {
						type : 'shadow'
					}
				},

				xAxis : [
					{
						type : 'value',
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff'
							}
						},
						splitLine:{
							lineStyle:{
								color:['#f2f2f2'],
								width:0,
								type:'solid'
							}
						}

					}
				],

				yAxis : [
					{
						type : 'category',
						data:['投入人数(人)', '投入人次(人)','人均费用(元)'],
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff'
							}
						},
						splitLine:{
							lineStyle:{
								width:0,
								type:'solid'
							}
						}
					}
				],

				series : [
					{
						name:'维度1',
						type:'bar',
						stack: '总量',
						itemStyle : { normal: {label : {show: true, position: 'insideRight'}}},
						data:[320, 302, 301]
					},
					{
						name:'维度2',
						type:'bar',
						stack: '总量',
						itemStyle : { normal: {label : {show: true, position: 'insideRight'}}},
						data:[120, 132, 101]
					},
					{
						name:'维度3',
						type:'bar',
						stack: '总量',
						itemStyle : { normal: {label : {show: true, position: 'insideRight'}}},
						data:[220, 182, 191]
					},
					{
						name:'维度4',
						type:'bar',
						stack: '总量',
						itemStyle : { normal: {label : {show: true, position: 'insideRight'}}},
						data:[150, 212, 201]
					}

				]

			}
		}
	}
}
</script>

<style scoped>

</style>
